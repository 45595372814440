import { gql } from "@apollo/client";
//All User mutations
export const ADD_USER = gql`
  mutation addUser($registerInput: RegisterInput!) {
    addUser(registerInput: $registerInput) {
      token
      user {
        _id
      }
    }
  }
`;
export const LOGIN_USER = gql`
  mutation login($loginInput: LoginInput!) {
    login( loginInput: $loginInput ) {
      token
      user {
        _id
      }
    }
  }
`;

export const LOGOUT_USER = gql`
  mutation logout {
    logout
  }
`;
export const UPDATE_USER = gql`
  mutation updateUser($updateInput: UpdateInput!) {
    updateUser(updateInput: $updateInput) {
      _id
      firstName
      lastName
      email
      password
      primaryAddress
      primaryPhone
      secondaryPhones
    }
  }
`;
// All Account mutations
export const UPDATE_ACCOUNT = gql`
  mutation updateAccount($accountId: ID!, $accountInput: AccountInput!) {
    updateAccountWithID(accountId: $accountId, accountInput: $accountInput) {
      id
      accountNumber
      address
      billingAddress
    }
  }
`;

export const ADD_QUOTE = gql`
  mutation addQuote($quoteInput: QuoteInput!) {
    addQuote(quoteInput: $quoteInput) {
      id
      services {
        _id
        service {
          _id
          name
          description
          imageUrl
        }
        frequency
        selectedTargetPests {
          _id
          name
        }
        price
        nextServiceDate
        approvedDate
      }
      user {
        _id
      }
      status
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_QUOTE = gql`
  mutation updateQuote($quoteId: ID!, $updateQuoteInput: UpdateQuoteInput!) {
    updateQuote(quoteId: $quoteId, updateQuoteInput: $updateQuoteInput) {
      id
      services {
        _id
        service {
          _id
          name
          description
          imageUrl
        }
        frequency
        selectedTargetPests {
          _id
          name
        }
        price
        nextServiceDate
        approvedDate
      }
      user {
        _id
      }
      status
      createdAt
      updatedAt
    }
  }
`;


export const ADD_SERVICE = gql`
  mutation AddService($serviceInput: AddServiceInput!) {
    addService(serviceInput: $serviceInput) {
      _id
      name
      description
      imageUrl
      targetPests {
        _id
        name
      }
    }
  }
`;

export const UPDATE_SERVICE = gql`
  mutation UpdateService($updateInput: UpdateServiceInput!) {
    updateService(updateInput: $updateInput) {
      _id
      name
      description
      imageUrl
      targetPests {
        _id
        name
      }
    }
  }
`;

export const VERIFY_EMAIL = gql`
  mutation VerifyEmail($token: String!) {
    verifyEmail(token: $token) {
      token
      user {
        _id
        email
        verified
      }
    }
  }
`;

export const REQUEST_PASSWORD_RESET = gql`
  mutation RequestPasswordReset($email: String!) {
    requestPasswordReset(email: $email)
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPassword($token: String!, $newPassword: String!) {
    resetPassword(token: $token, newPassword: $newPassword)
  }
`;
