import React from 'react';
import { Grid, Typography, Link, Box } from '@mui/material';
import './Footer.scss';

const Footer = () => {
  return (
    <Box component="footer" className="footerContainer">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" className="footerHeading">Contact Us</Typography>
          <Typography variant="body1" className="footerText">
            Customer Support & Service Inquiries: (516) 500-1502
          </Typography>
          <Typography variant="body1" className="footerText">
             Or (631) 500-0150
          </Typography>
          <Typography variant="body1" className="footerText">
            Email: <a href="mailto:service@lookuspest.com">service@lookuspest.com</a>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1" className="footerText">
            <Link href="/policy" className="footerLink" underline="hover">
              View Service Policy
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
