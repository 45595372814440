import React, { useState, useEffect } from "react";
import { useMutation, useQuery } from "@apollo/client";
import "./QuoteForm.scss";
import { ADD_QUOTE } from "../../utils/mutations";
import { GET_SERVICES } from "../../utils/queries";
import {
  Modal,
  Box,
  TextField,
  Button,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
} from "@mui/material";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  maxWidth: "90%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  overflow: "auto",
};

const QuoteForm = ({ open, handleClose }) => {
  const [formData, setFormData] = useState({
    locationAddress: "",
    billingAddress: "",
    locationInstructions: "",
  });
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState("");
  const [selectedPests, setSelectedPests] = useState([]);

  const {
    loading: servicesLoading,
    error: servicesError,
    data: servicesData,
  } = useQuery(GET_SERVICES);

  useEffect(() => {
    if (servicesData) {
      setServices(servicesData.allServices);
    }
  }, [servicesData]);

  const [quoteAdded, setQuoteAdded] = useState(false);

  const [addQuote, { loading, error }] = useMutation(ADD_QUOTE, {
    onCompleted: () => {
      handleClose();
      setQuoteAdded(true); // Set the state to true on successful completion
    },
    onError: (error) => console.error("Error submitting quote:", error),
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleServiceSelection = (e) => {
    const id = e.target.value;
    setSelectedService(id);
    const service = services.find((s) => s.id === id);
    if (service) {
      setSelectedPests(service.targetPests.map((pest) => pest.name)); // Map to pest IDs
    }
  };

  const handlePestsSelection = (e) => {
    const selectedPestIds = e.target.value;
    setSelectedPests(selectedPestIds);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setQuoteAdded(false); 
    addQuote({
      variables: {
        quoteInput: {
          services: [
            {
              service: selectedService, // Assuming selectedService is an ID
              selectedTargetPests: selectedPests,
              // Include other fields if necessary, like frequency, price, etc.
            },
          ],
          locationAddress: formData.locationAddress,
          billingAddress: formData.billingAddress,
          locationInstructions: formData.locationInstructions,
          // Include other fields if necessary
        },
      },
    });
  };

  if (servicesLoading) return <p>Loading services...</p>;
  if (servicesError)
    return (
      <p>An error occurred while fetching services: {servicesError.message}</p>
    );
  if (loading) return <p>Submitting Quote...</p>;
  if (error) return <p>An error occurred: {error.message}</p>;

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="quoteFormContainer"
    >
      <Box sx={modalStyle}>
        <Typography
          id="modal-modal-title"
          sx={{ textAlign: "center" }}
          variant="h3"
          component="h2"
          className="oswaldFont"
        >
          Request a Quote
        </Typography>
        {/* Conditionally render the success message */}
        {quoteAdded && (
          <Typography
            sx={{ mt: 2, textAlign: "center" }}
            color="success.main"
          >
            Your quote has been successfully submitted!
          </Typography>
        )}
        <Typography
          id="modal-modal-description"
          sx={{ m: 2, textAlign: "center" }}
          className="poppinsFont"
        >
          After submitting the form, a representative will contact you to either
          schedule a inspection or provide a quote.
        </Typography>
        <form onSubmit={handleSubmit} className="quoteForm">
          <TextField
            label="Location Address"
            name="locationAddress"
            className="formField"
            value={formData.locationAddress}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            required
          />
          <TextField
            label="Billing Address"
            name="billingAddress"
            className="formField"
            value={formData.billingAddress}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Location Instructions"
            name="locationInstructions"
            className="formField"
            value={formData.locationInstructions}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <FormControl fullWidth margin="normal">
            <InputLabel>Service</InputLabel>
            <Select
              value={selectedService}
              onChange={handleServiceSelection}
              required
            >
              {services.map((service) => (
                <MenuItem key={service._id} value={service._id}>
                  {service.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel>Target Pests</InputLabel>
            <Select
              multiple
              value={selectedPests}
              onChange={handlePestsSelection}
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((id) => {
                    // Find the pest object by its ID
                    const pest = services
                      .find((service) => service._id === selectedService)
                      ?.targetPests.find((pest) => pest._id === id);
                    // Use the pest name as the label
                    return <Chip key={id} label={pest ? pest.name : id} />;
                  })}
                </Box>
              )}
            >
              {services
                .find((service) => service._id === selectedService)
                ?.targetPests.map((pest) => (
                  <MenuItem key={pest._id} value={pest._id}>
                    {pest.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <Button type="submit" variant="contained" className="submitButton">
            Submit Quote
          </Button>
        </form>
      </Box>
    </Modal>
  );
};

export default QuoteForm;
