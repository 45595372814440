import React, { useState, useEffect } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { GET_QUOTE_BY_ID, GET_QUOTES_BY_STATUS } from "../../utils/queries";
import { UPDATE_QUOTE } from "../../utils/mutations";
import {
  Modal,
  Box,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
} from "@mui/material";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  maxWidth: "90%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  overflow: "scroll",
};

const EmployeeQuoteForm = ({ quote, handleClose }) => {
  const [quoteData, setQuoteData] = useState({
    id: "",
    locationAddress: "",
    billingAddress: "",
    locationInstructions: "",
    notes: "",
    services: [],
    status: "",
    // Add other fields as necessary
  });

  const { data, loading, error } = useQuery(GET_QUOTE_BY_ID, {
    variables: { id: quote.id },
  });

  useEffect(() => {
    if (data) {
      const loadedServices = data.getQuoteById.services.map((service) => ({
        id: service.service._id,
        name: service.service.name,
        selectedPests: service.selectedTargetPests.map((pest) => ({
          id: pest._id,
          name: pest.name,
        })),
      }));

      setQuoteData({
        id: data.getQuoteById.id,
        locationAddress: data.getQuoteById.locationAddress || "",
        billingAddress: data.getQuoteById.billingAddress || "",
        locationInstructions: data.getQuoteById.locationInstructions || "",
        notes: data.getQuoteById.notes || "",
        services: loadedServices,
        status: data.getQuoteById.status || "",
      });
    }
  }, [data]);

  const [updateQuote] = useMutation(UPDATE_QUOTE, {
    refetchQueries: [
      { query: GET_QUOTES_BY_STATUS, variables: { status: "PENDING" } },
    ],
  });

  const handleApproveOrDeny = (status) => {
    const formattedServices = quoteData.services.map(service => ({
      service: service.id, // ID of the service
      selectedTargetPests: service.selectedPests.map(pest => pest.id), // Array of pest IDs
      // Include other fields from SelectedServiceInput as required
    }));
  
    const formattedQuoteInput = {
      locationAddress: quoteData.locationAddress,
      billingAddress: quoteData.billingAddress,
      locationInstructions: quoteData.locationInstructions,
      notes: quoteData.notes,
      services: formattedServices,
      status: status, // Include the status
    };
  
    updateQuote({
      variables: {
        quoteId: quoteData.id,
        updateQuoteInput: formattedQuoteInput,
      },
    });
  
    handleClose();
  };
  

  const handleFieldChange = (event) => {
    const { name, value } = event.target;
    setQuoteData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleServiceChange = (event) => {
    const selectedServiceIds = event.target.value;
    const updatedServices = quoteData.services.map((service) => ({
      ...service,
      isSelected: selectedServiceIds.includes(service.id),
    }));

    setQuoteData((prevData) => ({
      ...prevData,
      services: updatedServices,
    }));
  };

  const handlePestsSelection = (event) => {
    const selectedPestIds = event.target.value;
    const updatedServices = quoteData.services.map((service) => ({
      ...service,
      selectedPests: service.selectedPests.map((pest) => ({
        ...pest,
        isSelected: selectedPestIds.includes(pest.id),
      })),
    }));

    setQuoteData((prevData) => ({
      ...prevData,
      services: updatedServices,
    }));
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <Modal open={true} onClose={handleClose} style={modalStyle}>
      <Box sx={{ bgcolor: "background.paper", p: 4 }}>
        <TextField
          name="locationAddress"
          label="Location Address"
          value={quoteData.locationAddress}
          onChange={handleFieldChange}
          fullWidth
          margin="normal"
        />
        <TextField
          name="billingAddress"
          label="Billing Address"
          value={quoteData.billingAddress}
          onChange={handleFieldChange}
          fullWidth
          margin="normal"
        />
        <TextField
          name="locationInstructions"
          label="Location Instructions"
          value={quoteData.locationInstructions}
          onChange={handleFieldChange}
          fullWidth
          margin="normal"
        />
        <TextField
          name="notes"
          label="Notes"
          value={quoteData.notes}
          onChange={handleFieldChange}
          fullWidth
          margin="normal"
        />
        <FormControl fullWidth margin="normal">
          <InputLabel>Services</InputLabel>
          <Select
            multiple
            value={quoteData.services.map((service) => service.id)}
            onChange={handleServiceChange}
            renderValue={(selected) => (
              <div>
                {quoteData.services
                  .filter((service) => selected.includes(service.id))
                  .map((service) => (
                    <Chip key={service.id} label={service.name} />
                  ))}
              </div>
            )}
          >
            {quoteData.services.map((service) => (
              <MenuItem key={service.id} value={service.id}>
                {service.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth margin="normal">
          <InputLabel>Target Pests</InputLabel>
          <Select
            multiple
            value={quoteData.services.flatMap((service) =>
              service.selectedPests.map((pest) => pest.id)
            )}
            onChange={handlePestsSelection}
            renderValue={(selected) => (
              <div>
                {quoteData.services
                  .flatMap((service) => service.selectedPests)
                  .filter((pest) => selected.includes(pest.id))
                  .map((pest) => (
                    <Chip key={pest.id} label={pest.name} />
                  ))}
              </div>
            )}
          >
            {quoteData.services
              .flatMap((service) => service.selectedPests)
              .map((pest) => (
                <MenuItem key={pest.id} value={pest.id}>
                  {pest.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>

        <TextField
          name="status"
          label="Status"
          value={quoteData.status}
          disabled
          fullWidth
          margin="normal"
        />
        <Button
          onClick={() => handleApproveOrDeny("APPROVED")}
          variant="contained"
          color="primary"
        >
          Approve
        </Button>
        <Button
          onClick={() => handleApproveOrDeny("DENIED")}
          variant="contained"
          color="secondary"
        >
          Deny
        </Button>
        <Button
          onClick={() => handleApproveOrDeny("PENDING")}
          variant="contained"
          color="success"
        >
          Update
        </Button>
      </Box>
    </Modal>
  );
};

export default EmployeeQuoteForm;

//EXAMPLE GET QUOTE BY STATUS QUERY RESPONSE:
// {
//   "data": {
//       "getQuotesByStatus": [
//           {
//               "id": "65da61e6a1810877891da00a",
//               "createdAt": "1708810726752",
//               "locationAddress": "999 Test ct",
//               "billingAddress": "999 Test ct",
//               "locationInstructions": "Off main st",
//               "notes": "Stated they have dogs 9:46PM",
//               "services": [
//                   {
//                       "_id": "65dd4d10c4b72da060363557",
//                       "approvedDate": null,
//                       "frequency": null,
//                       "nextServiceDate": null,
//                       "price": null,
//                       "selectedTargetPests": [
//                           {
//                               "_id": "65559a7089d5bdcaee0c8ce7",
//                               "description": "Ant infestations can undermine the integrity of your home and lead to health risks. Our pest control services are equipped to manage various ant species prevalent in Long Island, ensuring that your environment remains clean and safe. With strategic treatments, we disrupt ant colonies, preventing them from returning. Allow us to assist you in maintaining an ant-free home.",
//                               "imageUrl": "/assets/images/pests/general_insects/Ants.jpeg",
//                               "name": "Ants",
//                               "__typename": "Pest"
//                           },
//                           {
//                               "_id": "65559a7089d5bdcaee0c8ce9",
//                               "description": "Crickets are recognized by their nighttime chirping. Indoors, they can chew through fabrics, papers, and wallpaper. Crickets are also a source of food for other unwanted pests, potentially leading to a larger pest issue.",
//                               "imageUrl": "/assets/images/pests/general_insects/Cricket.jpeg",
//                               "name": "Crickets",
//                               "__typename": "Pest"
//                           },
//                           {
//                               "_id": "65559a7089d5bdcaee0c8ceb",
//                               "description": "Earwigs are menacing in appearance with their prominent pincers, which they use for defense. They are attracted to dark, moist environments and can damage young plants and flowers. While not harmful to humans, their presence can be alarming.",
//                               "imageUrl": "/assets/images/pests/general_insects/earwig.jpg",
//                               "name": "Earwigs",
//                               "__typename": "Pest"
//                           },
//                           {
//                               "_id": "65d7ee4934b106eac7d916df",
//                               "description": "Diverse in species, beetles can be destructive. Carpet beetles, for instance, feed on natural fibers, damaging carpets, furniture, and clothing. Others, like powderpost beetles, infest hardwood, undermining structural integrity. The shown ground beetle is typically found in soil and under debris but may venture indoors. While they are not damaging to home structures or fabrics, their presence can be a sign of other underlying issues, such as excess moisture or the need for better exterior sealing. Ground beetles primarily feed on other insects, so their appearance indoors could also indicate a larger pest population present. Proactive management of their environment is key to keeping these beetles in their natural outdoor habitat.",
//                               "imageUrl": "/assets/images/pests/general_insects/Ground_Beetle.jpeg",
//                               "name": "Beetles",
//                               "__typename": "Pest"
//                           }
//                       ],
//                       "service": {
//                           "_id": "65559a7089d5bdcaee0c8ced",
//                           "description": "You deserve a home that’s free of insects. That’s why we offer pest control services for all kinds of general insects, including ants, crickets, earwigs, and more. Contact us today and say goodbye to bugs!",
//                           "name": "General Insects",
//                           "imageUrl": "/assets/images/icons/ant-icon.png",
//                           "targetPests": [
//                               {
//                                   "name": null,
//                                   "__typename": "Pest"
//                               },
//                               {
//                                   "name": null,
//                                   "__typename": "Pest"
//                               },
//                               {
//                                   "name": null,
//                                   "__typename": "Pest"
//                               },
//                               {
//                                   "name": null,
//                                   "__typename": "Pest"
//                               },
//                               {
//                                   "name": null,
//                                   "__typename": "Pest"
//                               },
//                               {
//                                   "name": null,
//                                   "__typename": "Pest"
//                               },
//                               {
//                                   "name": null,
//                                   "__typename": "Pest"
//                               }
//                           ],
//                           "__typename": "Service"
//                       },
//                       "__typename": "SelectedService"
//                   }
//               ],
//               "user": {
//                   "_id": "65da6196a1810877891d9fff",
//                   "firstName": "zzzzz",
//                   "lastName": "zzzzz",
//                   "role": "USER",
//                   "__typename": "User"
//               },
//               "status": "PENDING",
//               "updatedAt": "1709002000656",
//               "__typename": "Quote"
//           }
//       ]
//   }
// }