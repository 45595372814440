import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import {
  GET_CUSTOMERS,
  GET_ACCOUNTS,
  GET_QUOTES_BY_STATUS,
} from "../../utils/queries";
import EmployeeQuoteForm from "../../components/EmployeeQuoteForm";

import "./Employee.scss";
import { DataGrid } from "@mui/x-data-grid";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import TextField from "@mui/material/TextField";

function Employee() {
  const [tab, setTab] = useState("customers");
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedQuote, setSelectedQuote] = useState(null);

  const { loading, error, data } = useQuery(
    tab === "customers"
      ? GET_CUSTOMERS
      : tab === "accounts"
      ? GET_ACCOUNTS
      : GET_QUOTES_BY_STATUS,
    { variables: { searchTerm, status: "PENDING" } }
  );

  // Define the columns for the DataGrid based on the selected alignment
  const columns = {
    customers: [
      { field: "id", headerName: "ID", width: 90 },
      { field: "firstName", headerName: "First name", width: 150 },
      { field: "lastName", headerName: "Last name", width: 150 },
      { field: "email", headerName: "Email", width: 250 },
      // Add more customer columns as needed
    ],
    accounts: [
      { field: "id", headerName: "ID", width: 90 },
      { field: "accountNumber", headerName: "Account Number", width: 150 },
      { field: "locationAddress", headerName: "Address", width: 250 },
      { field: "billingAddress", headerName: "Billing Address", width: 250 },
      { field: "balance", headerName: "Balance", width: 150},
      { field: "services", headerName: "Services", width: 250},
      { field: "user", headerName: "User", width: 250}
      // Add more account columns as needed
    ],
    quotes: [
      { field: "id", headerName: "Quote ID", width: 90 },
      { field: "serviceNames", headerName: "Service Names", width: 250 },
      { field: "targetPests", headerName: "Target Pests", width: 250 },
      { field: "status", headerName: "Status", width: 120 },
      { field: "userID", headerName: "User ID", width: 250 },
      { field: "currentUserRole", headerName: "Current User Role", width: 250 },
      // Add more quote columns as needed
    ],
  }[tab];

  // Transform the data for the DataGrid based on the toggle state
  const rows =
    {
      customers: data?.allCustomers?.map((customer) => ({
        id: customer._id,
        firstName: customer.firstName,
        lastName: customer.lastName,
        email: customer.email,
        // Add more customer details here
      })),
      accounts: data?.allAccounts?.map((account) => ({
        id: account.id,
        accountNumber: account.accountNumber,
        locationAddress: account.locationAddress,
        billingAddress: account.billingAddress,
        balance: account.balance,
        services: account.services.map((service) => service.service?.name).join(", "),
        user: account.user?.firstName + " " + account.user?.lastName,
        // Add more account details here
      })),
      quotes: data?.getQuotesByStatus?.map((quote) => ({
        id: quote.id,
        serviceNames: quote.services
          ?.map((service) => service.service?.name) // Added optional chaining
          .join(", "),
        targetPests: quote.services
          ?.flatMap((service) => 
            service.selectedTargetPests?.map((pest) => pest?.name) || [] // Added optional chaining and empty array fallback
          )
          .join(", "),
        status: quote.status,
        userID: quote.user._id,
        currentUserRole: quote.user.role,
        // Add more quote details here
      })) || [],
    }[tab] || [];

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
    setSelectedQuote(null); // Reset selected quote when changing tabs
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleRowClick = (params) => {
    if (tab === "quotes") {
      setSelectedQuote(params.row);
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <div className="contentWrapper">
      <div style={{ height: 400, width: "100%" }}>
        <Tabs value={tab} onChange={handleTabChange} aria-label="employee tabs">
          <Tab label="Customers" value="customers" />
          <Tab label="Accounts" value="accounts" />
          <Tab label="Quotes" value="quotes" />
        </Tabs>

        <TextField
          id="search-field"
          label="Search"
          variant="outlined"
          value={searchTerm}
          onChange={handleSearchChange}
          style={{ margin: "20px 0" }}
        />

        {rows && columns && (
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5, 10]}
            checkboxSelection
            onRowClick={handleRowClick}
          />
        )}

        {selectedQuote && (
          <EmployeeQuoteForm
            quote={selectedQuote}
            handleClose={() => setSelectedQuote(null)}
          />
        )}
      </div>
    </div>
  );
}

export default Employee;
