import React, { useState, useEffect } from "react";
import { TextField, Button, IconButton } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { useMutation } from "@apollo/client";
import { ADD_SERVICE, UPDATE_SERVICE } from "../../utils/mutations.js";

const ServiceEdit = ({ service, refetchServices }) => {
  const [serviceData, setServiceData] = useState({
    name: "",
    description: "",
    imageUrl: "",
    targetPests: [{ name: "", description: "", imageUrl: "" }],
  });

  // Define mutations for adding and updating services
  const [addService] = useMutation(ADD_SERVICE);
  const [updateService] = useMutation(UPDATE_SERVICE);

  useEffect(() => {
    if (service) {
      // Check if targetPests is an array before mapping
      const targetPestsArray = Array.isArray(service.targetPests)
        ? service.targetPests.map(pest => ({
            name: pest.name || "",
            description: pest.description || "",
            imageUrl: pest.imageUrl || ""
          }))
        : [];
        console.log(targetPestsArray);
        console.log(service);

      setServiceData({
        name: service.name || "",
        description: service.description || "",
        imageUrl: service.imageUrl || "",
        targetPests: targetPestsArray,
      });
    }
  }, [service]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name.startsWith("pest")) {
      const index = Number(name.split("-")[1]);
      const field = name.split("-")[2];
      const updatedPests = [...serviceData.targetPests];
      updatedPests[index][field] = value;
      setServiceData({ ...serviceData, targetPests: updatedPests });
    } else {
      setServiceData(prev => ({ ...prev, [name]: value }));
    }
  };

  const addPestField = () => {
    setServiceData(prevState => ({
      ...prevState,
      targetPests: [...prevState.targetPests, { name: "", description: "", imageUrl: "" }]
    }));
  };

  const removePestField = (index) => {
    setServiceData(prevState => ({
      ...prevState,
      targetPests: prevState.targetPests.filter((_, i) => i !== index)
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { name, description, imageUrl, targetPests } = serviceData;

    const serviceInput = {
      name,
      description,
      imageUrl,
      targetPests
    };

    try {
      if (service) {
        await updateService({ variables: { updateInput: { ...serviceInput, _id: service.id } }});
      } else {
        await addService({ variables: { serviceInput } });
      }
      refetchServices();
      setServiceData({ name: "", description: "", imageUrl: "", targetPests: [{ name: "", description: "", imageUrl: "" }] });
    } catch (error) {
      console.error("Error submitting the service:", error);
    }
  };

  return (
    <form onSubmit={handleSubmit} noValidate>
      {/* Service Name Input */}
      <TextField
        margin="normal"
        required
        fullWidth
        id="name"
        label="Service Name"
        name="name"
        autoComplete="name"
        autoFocus
        value={serviceData.name}
        onChange={handleChange}
      />
      {/* Description Input */}
      <TextField
        margin="normal"
        required
        fullWidth
        name="description"
        label="Description"
        type="text"
        id="description"
        autoComplete="description"
        value={serviceData.description}
        onChange={handleChange}
      />
      {/* Image URL Input */}
      <TextField
        margin="normal"
        required
        fullWidth
        name="imageUrl"
        label="Image URL"
        type="text"
        id="imageUrl"
        autoComplete="imageUrl"
        value={serviceData.imageUrl}
        onChange={handleChange}
      />
      {/* Target Pests Select */}
      {serviceData.targetPests.map((pest, index) => (
        <div key={index}>
          <TextField
            margin="normal"
            required
            fullWidth
            name={`pest-${index}-name`}
            label={`Pest Name ${index + 1}`}
            value={pest.name}
            onChange={handleChange}
          />
          <TextField
            margin="normal"
            fullWidth
            name={`pest-${index}-description`}
            label={`Pest Description ${index + 1}`}
            value={pest.description}
            onChange={handleChange}
          />
          <TextField
            margin="normal"
            fullWidth
            name={`pest-${index}-imageUrl`}
            label={`Pest Image URL ${index + 1}`}
            value={pest.imageUrl}
            onChange={handleChange}
          />
          {index > 0 && (
            <IconButton onClick={() => removePestField(index)}>
              <RemoveCircleOutlineIcon />
            </IconButton>
          )}
        </div>
      ))}
      <IconButton onClick={addPestField}>
        <AddCircleOutlineIcon />
      </IconButton>
      {/* Submit Button */}
      <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
        {service ? "Update Service" : "Add Service"}
      </Button>
    </form>
  );
};

export default ServiceEdit;
