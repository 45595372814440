import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { GET_USERS, GET_EMPLOYEES, GET_SERVICES } from "../../utils/queries.js";
import { ADD_SERVICE, UPDATE_SERVICE } from "../../utils/mutations.js";
import { DataGrid } from "@mui/x-data-grid";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ServiceEdit from "../../components/ServiceEdit/index.jsx";
import "./Admin.scss";

function Admin() {
  const [tab, setTab] = useState("users");
  const [selectedService, setSelectedService] = useState(null);

  // Initialize rows as empty arrays to avoid undefined errors
  const initialRows = {
    users: [],
    employees: [],
    services: [],
  };

  // Use state to manage the rows data
  const [rows, setRows] = useState(initialRows);

  // Queries for users, employees, and services
  const {
    loading: usersLoading,
    error: usersError,
    data: usersData,
  } = useQuery(GET_USERS);
  const {
    loading: employeesLoading,
    error: employeesError,
    data: employeesData,
  } = useQuery(GET_EMPLOYEES);
  const {
    loading: servicesLoading,
    error: servicesError,
    data: servicesData,
    refetch: refetchServices,
  } = useQuery(GET_SERVICES);

  // Mutation hooks for adding and updating services
  const [addService] = useMutation(ADD_SERVICE);
  const [updateService] = useMutation(UPDATE_SERVICE);

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
    // Reset selected service when changing tabs
    setSelectedService(null);
  };

  // Handler for selecting a service
  const handleServiceClick = (params) => {
    const service = rows.services.find((service) => service.id === params.id);
    setSelectedService(service);
  };

  // Populate rows when data is fetched successfully
  useEffect(() => {
    if (usersData && usersData.allUsers) {
      setRows((prevRows) => ({
        ...prevRows,
        users: usersData.allUsers.map((user) => ({
          id: user._id,
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          // Add more user details here
        })),
      }));
    }
    if (employeesData && employeesData.allEmployees) {
      setRows((prevRows) => ({
        ...prevRows,
        employees: employeesData.allEmployees.map((employee) => ({
          id: employee._id,
          firstName: employee.firstName,
          lastName: employee.lastName,
          email: employee.email,
          // Add more employee details here
        })),
      }));
    }
    if (servicesData && servicesData.allServices) {
      setRows((prevRows) => ({
        ...prevRows,
        services: servicesData.allServices.map((service) => ({
          id: service._id,
          name: service.name,
          description: service.description,
          imageUrl: service.imageUrl,
          targetPests: service.targetPests,
          targetPestsNames: service.targetPests
            .map((pest) => pest.name)
            .join(", "),
        })),
      }));
    }
  }, [usersData, employeesData, servicesData]);

  // Check for loading or errors
  if (usersLoading || employeesLoading || servicesLoading)
    return <p>Loading...</p>;
  if (usersError || employeesError || servicesError)
    return <p>Error loading data</p>;

  // Define the columns for the DataGrid based on the selected tab
  const columns = {
    users: [
      { field: "id", headerName: "ID", width: 90 },
      { field: "firstName", headerName: "First name", width: 150 },
      { field: "lastName", headerName: "Last name", width: 150 },
      { field: "email", headerName: "Email", width: 250 },
      // Add more user columns as needed
    ],
    employees: [
      { field: "id", headerName: "ID", width: 90 },
      { field: "firstName", headerName: "First name", width: 150 },
      { field: "lastName", headerName: "Last name", width: 150 },
      { field: "email", headerName: "Email", width: 250 },
      // Add more employee columns as needed
    ],
    services: [
      { field: "_id", headerName: "ID", width: 90 },
      { field: "name", headerName: "Service Type", width: 150 },
      { field: "description", headerName: "Description", width: 120 },
      { field: "imageUrl", headerName: "Image", width: 140 },
      { field: "targetPestsNames", headerName: "Target Pests", width: 250 },
      // Add more service columns as needed
    ],
  };

  return (
    <div className="contentWrapper">
      <div style={{ height: 400, width: "100%" }}>
        <Tabs value={tab} onChange={handleTabChange} aria-label="admin tabs">
          <Tab label="Users" value="users" />
          <Tab label="Employees" value="employees" />
          <Tab label="Services" value="services" />
        </Tabs>

        {tab === "users" && (
          <DataGrid
            rows={rows.users}
            columns={columns.users}
            pageSize={5}
            rowsPerPageOptions={[5, 10]}
            checkboxSelection
          />
        )}

        {tab === "employees" && (
          <DataGrid
            rows={rows.employees}
            columns={columns.employees}
            pageSize={5}
            rowsPerPageOptions={[5, 10]}
            checkboxSelection
          />
        )}

        {tab === "services" && (
          <>
            <ServiceEdit
              service={selectedService}
              addService={addService}
              updateService={updateService}
              refetchServices={refetchServices}
            />
            <DataGrid
              rows={rows.services}
              columns={columns.services}
              pageSize={5}
              rowsPerPageOptions={[5, 10]}
              checkboxSelection
              onRowClick={handleServiceClick}
            />
          </>
        )}
      </div>
    </div>
  );
}

export default Admin;
