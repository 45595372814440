import {gql, useQuery} from '@apollo/client';

export const GET_USER = gql`
query GetMyProfile {
  me {
    role
    firstName
    lastName
    email
    primaryAddress
    primaryPhone
    secondaryPhones
    verified
  }
}
`;
export function useGetUser() {
  // Execute the GET_USER query using Apollo's useQuery hook
  const { loading, error, data, refetch } = useQuery(GET_USER);
  
  // Handle loading and error states
  if (loading) return { loading };
  if (error) return { error };

  // Extract user information from the query result
  const user = data?.me;

  return { 
    user,
    refetch
  };
}

export function useIsAuthenticated() {
  const { loading, error, data } = useQuery(GET_USER, {
    fetchPolicy: 'network-only',
  });

  if (loading) return { loading };
  if (error) return { error };


  // Check if the user data is valid (not null or undefined)
  const isAuthenticated = Boolean(data?.me);

  return { isAuthenticated };
}
