import ReactDOM from 'react-dom/client'
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ProtectedRoute, GeneralProtectedRoute, AdminProtectedRoute } from './components/ProtectedRoute/index.jsx';
import App from './App.jsx'
import Home from './pages/Home';
import Login from './pages/Login';
import Register from './pages/Register';
import Employee from './pages/Employee';
import Profile from './pages/Profile';
import Admin from './pages/Admin';
import VerifyEmail from './pages/VerifyEmail/index.jsx';
import VerifyEmailPrompt from './pages/VerifyEmailPrompt/index.jsx';
import ResetPassword from './pages/ResetPassword/index.jsx';
import ForgotPassword from './pages/ForgotPassword/index.jsx';
import CustomerAccount from './pages/CustomerAccount';
import PolicyPage from './pages/Policy/index.jsx';
import ErrorPage from './pages/Error';
import PaymentSuccessPage from './pages/StripePages/PaymentSuccessPage.jsx';
import PaymentFailurePage from './pages/StripePages/PaymentFailurePage.jsx';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <Home />
      }, {
        path: '/login',
        element: <Login />
      }, {
        path: '/register',
        element: <Register />
      }, {
        path: '/profile',
        element: (
           <GeneralProtectedRoute><Profile /></GeneralProtectedRoute>
        ),
      },{
        path: '/employee',
        element: (
          <ProtectedRoute><Employee /></ProtectedRoute>
        ),
      },{
        path: '/admin',
        element: (
          <AdminProtectedRoute><Admin /></AdminProtectedRoute>
        ),
      },{
        path: '/verify-email/:token',
        element: <VerifyEmail />
      },{
        path: '/verify-email-prompt',
        element: <VerifyEmailPrompt />
      },{
        path: '/reset-password/:token',
        element: <ResetPassword />
      },{
        path: '/forgot-password',
        element: <ForgotPassword />
      },{
        path: '/customer-account',
        element: (
          <GeneralProtectedRoute><CustomerAccount /></GeneralProtectedRoute>
        ),
      }, {
        path: '/policy',
        element: <PolicyPage />
      }, {
        path: '/payment-success',
        element: <PaymentSuccessPage />
      }, {
        path: '/payment-failure',
        element: <PaymentFailurePage />
      }
    ]
  },
]);

ReactDOM.createRoot(document.getElementById('root')).render(
  <RouterProvider router={router} />
)
