// client/src/pages/ForgotPassword/index.jsx
import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { REQUEST_PASSWORD_RESET } from '../../utils/mutations';
import { Button, TextField, Typography, Container } from '@mui/material';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [requestPasswordReset, { data, loading, error }] = useMutation(REQUEST_PASSWORD_RESET);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await requestPasswordReset({ variables: { email } });
      // Handle success (show confirmation message)
    } catch (e) {
      // Handle error
    }
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h5">Reset Password</Typography>
      <form onSubmit={handleSubmit} noValidate autoComplete="off">
        <TextField
          label="Email Address"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          fullWidth
          required
          margin="normal"
        />
        <Button type="submit" color="primary" variant="contained" disabled={loading}>Send Reset Link</Button>
      </form>
      {data && <Typography>Check your email for a reset link.</Typography>}
      {error && <Typography color="error">Error sending reset link.</Typography>}
    </Container>
  );
};

export default ForgotPassword;
