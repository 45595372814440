import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
} from "@apollo/client";
import { UserContext } from "./userContext";
import "./App.scss";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

// Assuming you have your Stripe publishable key stored in an environment variable
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const httpLink = createHttpLink({
  uri: "/graphql",
  credentials: "same-origin",
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: httpLink,
});

function App() {
  const [user, setUser] = useState(null); // Create a state to hold the user data

  return (
    <ApolloProvider client={client}>
      <UserContext.Provider value={{ user, setUser }}>
        <Elements stripe={stripePromise}>
          <div className="bodyWrapper">
            <Navbar />
            <main className="content">
              <Outlet />
            </main>
            <Footer />
          </div>
        </Elements>
      </UserContext.Provider>
    </ApolloProvider>
  );
}

export default App;
