import React from "react";
import { Container, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const PaymentSuccessPage = () => {
  const navigate = useNavigate();

  return (
    <Container maxWidth="sm" style={{ marginTop: '20px' }}>
      <Typography variant="h4" gutterBottom>
        Payment Successful
      </Typography>
      <Typography variant="body1" paragraph>
        Thank you for your payment. Your transaction has been completed, and a receipt for your purchase has been emailed to you.
      </Typography>
      <Button variant="contained" color="primary" onClick={() => navigate('/')}>
        Return Home
      </Button>
    </Container>
  );
};

export default PaymentSuccessPage;
