import React, { useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { VERIFY_EMAIL } from '../../utils/mutations';
import { UserContext } from '../../userContext';

const VerifyEmail = () => {
  const navigate = useNavigate();
  const { token } = useParams();
  const { setUser } = useContext(UserContext);
  const [verifyEmail, { data, loading, error }] = useMutation(VERIFY_EMAIL);

  useEffect(() => {
    if (token) {
      verifyEmail({ variables: { token } });
    }
  }, [token, verifyEmail]);

  useEffect(() => {
    if (data) {
      // Assuming the mutation returns the user and token upon successful verification
      const { user, token } = data.verifyEmail;
      setUser(user); // Update user context
      localStorage.setItem('token', token); // Save token for session management
      navigate('/'); // Redirect to homepage or dashboard after verification
    }
  }, [data, navigate, setUser]);

  if (loading) return <p>Verifying...</p>;
  if (error) return <p>An error occurred: {error.message}</p>;

  return (
    <div>
      <h2>Email verified successfully!</h2>
      <p>You will be redirected shortly...</p>
    </div>
  );
};

export default VerifyEmail;
