import React from "react";
import { useQuery } from "@apollo/client";
import { GET_MY_ACCOUNTS } from "../../utils/queries";
import PaymentButton from "../../components/PaymentButton";
import { Button, Grid, Typography, Box, Container } from "@mui/material";

const CustomerAccountPage = () => {
  // Fetch the account details
  const { loading, error, data } = useQuery(GET_MY_ACCOUNTS);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading account details: {error.message}</p>;

  const accounts = data?.getMyAccounts || [];

  // A function to get the current invoice ID safely
  const getCurrentInvoiceId = (invoices) => {
    if (!invoices || invoices.length === 0) {
      return "no-invoice"; // Placeholder, or you could return undefined and handle it in the PaymentButton
    }
    // Assume the last invoice is the current one
    return invoices[invoices.length - 1].id;
  };

  return (
    <Container>
      <Typography variant="h2">Customer Account Details</Typography>
      {accounts.map((account) => (
        <Box key={account.id} mt={4}>
          <Typography variant="h4">
            Account Number: {account.accountNumber}
          </Typography>
          <Typography variant="body1">
            Invoice Billing Cycle: Start-
            {new Date(
              parseInt(account.invoices[0].billingPeriodStartDate)
            ).toLocaleDateString()}{" "}
            - End-
            {new Date(
              parseInt(account.invoices[0].billingPeriodEndDate)
            ).toLocaleDateString()}
          </Typography>

          <Typography variant="body1">
            Balance: ${account.balance.toFixed(2)}
          </Typography>
          <Typography variant="h5">Invoices: {account.invoices.id}</Typography>
          <Typography variant="body1">Charges: {account.invoices.charges} </Typography>
          <Typography variant="body1">Services:</Typography>
          <ul>
            {account.services.map((service) => (
              <li key={service.service.name}>{service.service.name}</li>
            ))}
          </ul>
          <Typography variant="body1">
            Customer Since:{" "}
            {new Date(parseInt(account.createdAt)).toLocaleDateString()}
          </Typography>
          <PaymentButton
            accountId={account.id}
            invoiceId={getCurrentInvoiceId(account.invoices)}
          />
        </Box>
      ))}
    </Container>
  );
};

export default CustomerAccountPage;
