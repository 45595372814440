import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { UserContext } from "../../userContext";
import { useGetUser } from "../../utils/auth";
import { useMutation } from "@apollo/client";
import { LOGOUT_USER } from "../../utils/mutations";
import "./Navbar.scss";
// Material UI
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import CssBaseline from "@mui/material/CssBaseline";

const Navbar = () => {
  const { user, setUser } = useContext(UserContext);
  const { user: fetchedUser } = useGetUser();
  const [logoutUser] = useMutation(LOGOUT_USER);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  useEffect(() => {
    if (fetchedUser) {
      setUser(fetchedUser);
    }
  }, [fetchedUser, setUser]);

  useEffect(() => {
    // Redirect user to verify email prompt if they are logged in but not verified
    if (!user === null && !user.verified) {
      navigate("/verify-email-prompt");
    }
  }, [user, navigate]);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = async (event) => {
    event.preventDefault();
    try {
      const { data } = await logoutUser();
      if (data.logout) {
        setUser(null);
        navigate("/");
      }
    } catch (error) {
      console.error("Failed to logout:", error);
    }
  };

  const renderMenuItems = () => {
    switch (user?.role) {
      case "ADMIN":
        return [
          <MenuItem key="home" component={Link} to="/">
            Home Page
          </MenuItem>,
          <MenuItem key="profile" component={Link} to="/profile">
            My Profile
          </MenuItem>,
          <MenuItem key="Accounts" component={Link} to="/customer-account">
            My Accounts
          </MenuItem>,
          <MenuItem key="admin" component={Link} to="/admin">
            Admin Panel
          </MenuItem>,
          <MenuItem key="employee" component={Link} to="/employee">
            Employee Panel
          </MenuItem>,
          // Add more admin-specific items here
        ];
      case "EMPLOYEE":
        return [
          <MenuItem key="home" component={Link} to="/">
            Home Page
          </MenuItem>,
          <MenuItem key="profile" component={Link} to="/profile">
            My Profile
          </MenuItem>,
          <MenuItem key="employee" component={Link} to="/employee">
            Employee Panel
          </MenuItem>,
          // Add more employee-specific items here
        ];
      case "CUSTOMER":
        return [
          <MenuItem key="home" component={Link} to="/">
            Home Page
          </MenuItem>,
          <MenuItem key="profile" component={Link} to="/profile">
            My Profile
          </MenuItem>,
          <MenuItem key="Accounts" component={Link} to="/customer-account">
            My Accounts
          </MenuItem>,
          // Add more customer-specific items here
        ];
      case "USER":
        return [
          <MenuItem key="home" component={Link} to="/">
            Home Page
          </MenuItem>,
          <MenuItem key="profile" component={Link} to="/profile">
            My Profile
          </MenuItem>,
          // Add more general user items here
        ];
      default:
        return [
          <MenuItem key="login" component={Link} to="/login">
            Login
          </MenuItem>,
          <MenuItem key="register" component={Link} to="/register">
            Register
          </MenuItem>,
        ];
    }
  };

  return (
    <>
      <CssBaseline /> {/* Helps normalize default styles */}
      <Box sx={{ flexGrow: 1 }} className="navbarContainer">
        <AppBar position="fixed" className="appBar">
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              aria-label="menu"
              sx={{ mr: 2 }}
              className="menuButton"
              onClick={handleMenu}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={open}
              onClose={handleClose}
            >
              {renderMenuItems()}
            </Menu>
            {/* Logo Replacement */}
            <Link to="/" style={{ textDecoration: "none" }}>
              {" "}
              <Box
                component="img"
                sx={{
                  height: 40, // Adjust size as needed
                  mr: 2,
                  cursor: "pointer", // Changes the cursor to a pointer on hover
                }}
                className="logo"
                src="/assets/images/logos/Lookus.png"
                alt="Lookus Logo"
              />
            </Link>
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1 }}
            ></Typography>
            {user && user.firstName ? (
              <>
                <Typography variant="subtitle1" sx={{ mr: 2 }}>
                  Welcome, {user.firstName}!
                </Typography>
                <Button color="inherit" onClick={logout}>
                  Logout
                </Button>
              </>
            ) : (
              <>
                <Button className="loginButton" component={Link} to="/login">
                  Login
                </Button>
                <Button
                  className="registerButton"
                  component={Link}
                  to="/register"
                >
                  Register
                </Button>
              </>
            )}
          </Toolbar>
        </AppBar>
        {/* Add a toolbar here to offset content below the AppBar */}
        <Toolbar />
      </Box>
    </>
  );
};

export default Navbar;
