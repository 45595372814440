import React from "react";
import { Typography, Container, Box } from "@mui/material";
import "./Policy.scss";

const PolicyPage = () => {
  return (
    <Container maxWidth="md">
      <Box className="policy-container">
        <Typography
          variant="h4"
          gutterBottom
          component="div"
          className="policy-heading"
        >
          Lookus Service Policy
        </Typography>

        {/* Customer Service Contact Details */}
        <Typography variant="h6" gutterBottom className="policy-subheading">
          Customer Service Contact Details
        </Typography>
        <Typography paragraph>
          <strong>Lookus Pest Control LLC / Lookus Home Improvement LLC</strong>
          <br />
          Email: service@lookuspest.com
          <br />
          Phone: 631-500-0150
          <br />
          Address: 104 Elder Drive, Mastic Beach, NY 11951
        </Typography>

        {/* Service Completion Policy */}
        <Typography variant="h6" gutterBottom className="policy-subheading">
          Service Completion Policy
        </Typography>
        <Typography paragraph>
          All services provided by Lookus Pest Control LLC and Lookus Home
          Improvement LLC are considered final upon completion. Due to the
          nature of our services, we cannot accept returns. Service
          dissatisfaction or disputes will be handled on a case-by-case basis at
          the discretion of management.
        </Typography>

        {/* Refund and Dispute Policy */}
        <Typography variant="h6" gutterBottom className="policy-subheading">
          Refund and Dispute Policy
        </Typography>
        <Typography paragraph>
          Refunds are issued under the following conditions:
          <ol>
            <li>
              Cancellation before service commencement: Full refund of any
              advance payments.
            </li>
            <li>
              Service dissatisfaction: At management's discretion, a refund or
              discount may be offered.
            </li>
          </ol>
          To initiate a refund or dispute a charge, please contact our customer
          service team at service@lookuspest.com or 631-500-0150.
        </Typography>

        {/* Cancellation Policy */}
        <Typography variant="h6" gutterBottom className="policy-subheading">
          Cancellation Policy
        </Typography>
        <Typography paragraph>
          Customers may cancel their service appointment at any time before the
          service is completed without any cancellation fees. Only completed
          work is payable.
        </Typography>

        {/* Legal and Geographic Service Area */}
        <Typography variant="h6" gutterBottom className="policy-subheading">
          Legal and Geographic Service Area
        </Typography>
        <Typography paragraph>
          Our services are performed locally within most areas of Long Island.
          Access to Stripe payment options is restricted to approved customers
          within our service area.
        </Typography>

        {/* Promotions and Discounts */}
        <Typography variant="h6" gutterBottom className="policy-subheading">
          Promotions and Discounts
        </Typography>
        <Typography paragraph>
          Promotions and discounts are occasionally offered and will be
          communicated via our website or email to subscribers. Specific terms
          and conditions, including validity periods and applicability, will be
          detailed with each promotion.
        </Typography>

        {/* Transaction Currency */}
        <Typography variant="h6" gutterBottom className="policy-subheading">
          Transaction Currency
        </Typography>
        <Typography paragraph>
          All transactions are conducted in USD.
        </Typography>

        {/* Data Security and Privacy */}
        <Typography variant="h6" gutterBottom className="policy-subheading">
          Data Security and Privacy
        </Typography>
        <Typography paragraph>
          Lookus Pest Control LLC / Lookus Home Improvement LLC does not store
          customer payment card details. All payment transactions are securely
          processed through Stripe. We collect customer data such as name,
          address, and contact information for internal use only, to manage
          customer accounts and service appointments. This data is not shared
          with third parties.
        </Typography>
        <Typography paragraph>
          Customers wishing to access or request deletion of their data can
          contact us at service@lookuspest.com.
        </Typography>
      </Box>
    </Container>
  );
};

export default PolicyPage;
