import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../userContext";
import { useQuery } from "@apollo/client";
import { GET_SERVICES } from "../../utils/queries";
import ServiceCard from "../../components/ServiceCard";
import { QuoteForm } from "../../components";
import { Button, Grid, Typography, Box, Container } from "@mui/material";
import "./Home.scss";

function Home() {
  const [quoteFormOpen, setQuoteFormOpen] = useState(false);
  const { loading, error, data } = useQuery(GET_SERVICES);
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  const handleOpenQuoteForm = () => {
    if ( !user.role ) {
      navigate('/login'); // Redirect to login if not logged in
    } else {
      setQuoteFormOpen(true);
    }
  };
  const handleCloseQuoteForm = () => setQuoteFormOpen(false);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <>
      {/* Hero Banner Section outside of Container for full width */}
      <div className="heroBanner">
        <Button
          variant="contained"
          size="large"
          color="primary"
          onClick={handleOpenQuoteForm}
          className="heroCTA" // Apply the class for styling
        >
          Request a Quote
        </Button>
      </div>
      <Container maxWidth="lg">
        <Box sx={{ my: 4, textAlign: "center" }}>
          <Typography variant="h1" gutterBottom className="oswaldFont">
            Pest Control Services
          </Typography>
          <Typography variant="h4" color="textSecondary" paragraph>
            Your local pest control experts
          </Typography>
        </Box>
        <Box sx={{ overflowX: "auto" }}>
          <Grid
            container
            spacing={3}
            direction="row"
            wrap="nowrap"
            justifyContent="flex-start"
          >
            {data.allServices.map((service) => (
              <Grid item key={service.id}>
                <ServiceCard service={service} />
              </Grid>
            ))}
          </Grid>
        </Box>
        <QuoteForm open={quoteFormOpen} handleClose={handleCloseQuoteForm} />
      </Container>
    </>
  );
}

export default Home;
