import React, { useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { useLazyQuery } from "@apollo/client";
import { CREATE_STRIPE_CHECKOUT_SESSION } from "../../utils/queries";
import { Button } from "@mui/material";

const StripeKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
const stripePromise = loadStripe(StripeKey);

const PaymentButton = ({ accountId, invoiceId }) => {
  const [getCheckoutSession, { data, loading, error }] = useLazyQuery(
    CREATE_STRIPE_CHECKOUT_SESSION,
    {
      variables: { accountId, invoiceId },
      fetchPolicy: "network-only",
    }
  );

  useEffect(() => {
    if (data) {
      stripePromise.then((stripe) => {
        stripe.redirectToCheckout({ sessionId: data.checkout.session });
      });
    }
  }, [data]);

  const handleClick = () => {
    if (!loading && !error) {
      getCheckoutSession();
    }
  };

  return (
    <Button onClick={handleClick} disabled={loading || error} variant="contained">
      {loading ? "Processing..." : "Pay Now"}
    </Button>
  );
};

export default PaymentButton;
