//Error handling page
import React from "react";
import { Typography, Container } from "@mui/material";

const ErrorPage = () => {
    return (
        <Container maxWidth="sm" style={{ marginTop: "20px" }}>
        <Typography variant="h4" gutterBottom>
            Error
        </Typography>
        <Typography variant="body1" paragraph>
            An error occurred. Please try again later.
        </Typography>
        </Container>
    );
    }

export default ErrorPage;
