import React, { useState } from "react";
import "./ServiceCard.scss";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const ServiceCard = ({ service }) => {
  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  // Function to truncate the description if it's too long
  const truncateDescription = (description, maxLength = 100) => {
    if (description.length > maxLength) {
      return `${description.substring(0, maxLength)}...`;
    }
    return description;
  };

  return (
    <Card className="card" sx={{ boxShadow: 3, mb: 2 }}>
      <CardMedia
        component="img"
        height="325"
        image={service.imageUrl}
        alt={service.name}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div" className="oswaldFont">
          {service.name}
        </Typography>
        {showMore ? (
          <Typography variant="body2" color="text.secondary" className="poppinsFont">
            {service.description}
          </Typography>
        ) : (
          <Typography variant="body2" color="text.secondary" className="poppinsFont">
            {truncateDescription(service.description)}
          </Typography>
        )}
        <Button
          className="poppinsFont"
          onClick={toggleShowMore}
          size="small"
          sx={{ display: "block", mt: 1 }}
        >
          {showMore ? "Show Less" : "Show More..."}
        </Button>
      </CardContent>
      {showMore && (
        <Box sx={{ maxHeight: 300, overflow: "auto" }}>
          {service.targetPests.map((pest, index) => (
            <Accordion key={index} TransitionProps={{ unmountOnExit: true }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index}a-content`}
                id={`panel${index}a-header`}
              >
                <Typography className="oswaldFont">{pest.name}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <CardMedia
                  component="img"
                  height="140"
                  image={pest.imageUrl || "/path/to/default/image"}
                  alt={pest.name}
                />
                <Typography variant="body2" color="text.secondary" className="poppinsFont">
                  {pest.description || "No description available."}
                </Typography>
                <Button size="small">Learn More</Button>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      )}
    </Card>
  );
};

export default ServiceCard;
