import React from 'react';
import { Typography, Button, Container } from '@mui/material';
import { useNavigate } from "react-router-dom";

const PaymentFailurePage = () => {
    const navigate = useNavigate();

  return (
    <Container maxWidth="sm" style={{ marginTop: '20px' }}>
      <Typography variant="h4" gutterBottom>
        Payment Cancelled or Failed
      </Typography>
      <Typography variant="body1" paragraph>
        Your payment process was not completed. If this was an error, please attempt your transaction again or contact customer support for assistance.
      </Typography>
      <Button variant="contained" color="secondary" onClick={() => navigate('/checkout')}>
        Try Again
      </Button>
    </Container>
  );
};


export default PaymentFailurePage;
