import React, { useState, useContext, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { GET_MY_QUOTES, GET_MY_ACCOUNTS } from "../../utils/queries";
import { UPDATE_USER } from "../../utils/mutations";
import { UserContext } from "../../userContext";
import { useGetUser } from "../../utils/auth";
import "./Profile.scss";

import {
  TextField,
  Container,
  Typography,
  IconButton,
  Box,
  Card,
  CardContent,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";

function Profile() {
  const { user, setUser } = useContext(UserContext);
  const { loading, user: fetchedUser, refetch } = useGetUser();
  // Fetch pending quotes
  const {
    data: quotesData,
    loading: quotesLoading,
    error: quotesError,
  } = useQuery(GET_MY_QUOTES);
  // Fetch account details
  const {
    data: accountsData,
    loading: accountsLoading,
    error: accountsError,
  } = useQuery(GET_MY_ACCOUNTS);

  // Initialize state outside of any conditions
  const [editMode, setEditMode] = useState({
    firstName: false,
    lastName: false,
    email: false,
    primaryPhone: false,
    primaryAddress: false,
    secondaryPhones: false,
  });

  const [formState, setFormState] = useState({
    firstName: "",
    lastName: "",
    email: "",
    primaryPhone: "",
    primaryAddress: "",
    secondaryPhones: "",
  });

  const [updateUser] = useMutation(UPDATE_USER);

  // Effect for fetching user data
  useEffect(() => {
    if (!user && fetchedUser) {
      setUser(fetchedUser); // Update the context if the user is fetched
    } else if (!user) {
      refetch(); // Attempt to refetch the user data if not present
    }
  }, [user, fetchedUser, setUser, refetch]);

  // Effect for setting form state when user data is available
  useEffect(() => {
    if (user) {
      setFormState({
        firstName: user.firstName || "",
        lastName: user.lastName || "",
        email: user.email || "",
        primaryPhone: user.primaryPhone || "",
        primaryAddress: user.primaryAddress || "",
        secondaryPhones: user.secondaryPhones?.join(", ") || "",
      });
    }
  }, [user]);

  // If the user data is still loading or not available, show a loading message
  if (loading || !user) {
    return <div>Loading profile...</div>;
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const handleEditClick = (field) => {
    setEditMode({ ...editMode, [field]: true });
  };

  const handleCancelClick = (field) => {
    setEditMode({ ...editMode, [field]: false });
    // Reset the form state to the initial user data if cancel is clicked
    setFormState({ ...formState, [field]: user[field] || "" });
  };

  const handleSaveClick = async (field) => {
    setEditMode({ ...editMode, [field]: false });
    try {
      const { data } = await updateUser({
        variables: {
          updateInput: {
            ...formState,
            [field]: formState[field],
            // Convert secondaryPhones string to an array, split by commas
            secondaryPhones: formState.secondaryPhones
              ? formState.secondaryPhones
                  .split(",")
                  .map((phone) => phone.trim())
              : [],
          },
        },
      });
      // Handle the response data
      console.log(data);
    } catch (e) {
      console.error("Error updating user profile:", e);
    }
  };

  // Render input field with edit/save/cancel buttons
  const renderEditableField = (field, label, type = "text") => (
    <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
      <TextField
        margin="normal"
        required
        fullWidth
        id={field}
        label={label}
        name={field}
        autoComplete={field}
        autoFocus
        value={formState[field]}
        onChange={handleInputChange}
        disabled={!editMode[field]}
        type={type}
      />
      {!editMode[field] ? (
        <IconButton onClick={() => handleEditClick(field)}>
          <EditIcon />
        </IconButton>
      ) : (
        <>
          <IconButton onClick={() => handleSaveClick(field)}>
            <SaveIcon />
          </IconButton>
          <IconButton onClick={() => handleCancelClick(field)}>
            <CancelIcon />
          </IconButton>
        </>
      )}
    </div>
  );

  return (
    <div className="contentWrapper">
      <Container component="main" maxWidth="xs">
        <Typography component="h1" variant="h5">
          Edit Profile
        </Typography>
        <form onSubmit={(e) => e.preventDefault()}>
          {renderEditableField("firstName", "First Name")}
          {renderEditableField("lastName", "Last Name")}
          {renderEditableField("email", "Email Address", "email")}
          {renderEditableField("primaryPhone", "Primary Phone", "tel")}
          {user.role === "USER" || user.role === "CUSTOMER"
            ? renderEditableField("primaryAddress", "Primary Address")
            : null}
          {renderEditableField(
            "secondaryPhones",
            "Secondary Phones (comma separated)",
            "tel"
          )}
        </form>
      </Container>
      {quotesLoading ? (
        <p>Loading quotes...</p>
      ) : quotesError ? (
        <p>Error loading quotes.</p>
      ) : (
        <Box>
          <Typography variant="h6">My Pending Quotes</Typography>
          {quotesData.getMyQuotes.map((quote) => (
            <Card key={quote.id} variant="outlined" sx={{ mb: 2 }}>
              <CardContent>
                <Typography variant="body1">
                  Service:{" "}
                  {quote.services
                    .map((service) => service.service.name)
                    .join(", ")}
                </Typography>
                <Typography variant="body2">Status: {quote.status}</Typography>
                <Typography variant="body2">
                  Date: {new Date(quote.createdAt).toLocaleDateString()}
                </Typography>
              </CardContent>
            </Card>
          ))}
        </Box>
      )}

      {accountsLoading ? (
        <p>Loading accounts...</p>
      ) : accountsError ? (
        <p>Error loading accounts.</p>
      ) : (
        <Box>
          <Typography variant="h6">My Accounts</Typography>
          {accountsData.getMyAccounts.map((account) => (
            <Card key={account.id} variant="outlined" sx={{ mb: 2 }}>
              <CardContent>
                <Typography variant="body1">
                  Account Number: {account.accountNumber}
                </Typography>
                <Typography variant="body2">
                  Balance: ${account.balance.toFixed(2)}
                </Typography>
                <Typography variant="body2">
                  Services:{" "}
                  {account.services
                    .map((service) => service.service.name)
                    .join(", ")}
                </Typography>
              </CardContent>
            </Card>
          ))}
        </Box>
      )}
    </div>
  );
}

export default Profile;
