import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { RESET_PASSWORD } from '../../utils/mutations';
import { Button, TextField, Typography, Container } from '@mui/material';
import { useParams } from 'react-router-dom';

const ResetPassword = () => {
  const { token } = useParams();
  const [newPassword, setNewPassword] = useState('');
  const [resetPassword, { data, loading, error }] = useMutation(RESET_PASSWORD);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await resetPassword({ variables: { token, newPassword } });
      // Handle success
    } catch (e) {
      // Handle error
    }
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h5">Reset Password</Typography>
      <form onSubmit={handleSubmit} noValidate autoComplete="off">
        <TextField
          label="New Password"
          type="password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          fullWidth
          required
          margin="normal"
        />
        <Button type="submit" color="primary" variant="contained" disabled={loading}>Reset Password</Button>
      </form>
      {data && <Typography>Your password has been reset. You can now <a href="/login">log in</a>.</Typography>}
      {error && <Typography color="error">Error resetting your password.</Typography>}
    </Container>
  );
};

export default ResetPassword;