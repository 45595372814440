import { gql } from "@apollo/client";
// import {useState} from 'react';

export const GET_USERS = gql`
  query GetUsers {
    allUsers {
      _id
      firstName
      lastName
      email
    }
  }
`;

export const GET_EMPLOYEES = gql`
  query GetEmployees {
    allEmployees {
      _id
      firstName
      lastName
      email
    }
  }
`;

export const GET_CUSTOMERS = gql`
  query GetCustomers {
    allCustomers {
      _id
      firstName
      lastName
      email
    }
  }
`;

export const GET_ACCOUNTS = gql`
  query GetAccounts($searchTerm: String) {
    allAccounts(searchTerm: $searchTerm) {
      id
      accountNumber
      locationAddress
      billingAddress
      balance
      services {
        _id
        service {
          _id
          name
        }
        frequency
        selectedTargetPests {
          _id
          name
        }
        price
        nextServiceDate
        approvedDate
      }
      user {
        _id
        firstName
        lastName
        primaryPhone
        secondaryPhones
      }
      notes
    }
  }
`;

export const GET_SERVICES = gql`
  query getServices {
    allServices {
      _id
      name
      description
      imageUrl
      targetPests {
        _id
        name
        description
        imageUrl
      }
    }
  }
`;

// Add the new query for fetching quotes by status
export const GET_QUOTES_BY_STATUS = gql`
  query GetQuotesByStatus($status: QuoteStatus!) {
    getQuotesByStatus(status: $status) {
      id
      createdAt
      locationAddress
      billingAddress
      locationInstructions
      notes
      services {
        _id
        approvedDate
        frequency
        nextServiceDate
        price
        selectedTargetPests {
          _id
          description
          imageUrl
          name
        }
        service {
          _id
          description
          name
          imageUrl
          targetPests {
            name
          }
        }
      }
      user {
        _id
        firstName
        lastName
        role
      }
      status
      createdAt
      updatedAt
    }
  }
`;

export const GET_QUOTE_BY_ID = gql`
  query GetQuoteById($id: ID!) {
    getQuoteById(id: $id) {
      id
      createdAt
      locationAddress
      billingAddress
      locationInstructions
      notes
      services {
        _id
        approvedDate
        frequency
        nextServiceDate
        price
        selectedTargetPests {
          _id
          description
          imageUrl
          name
        }
        service {
          _id
          description
          name
          imageUrl
          targetPests {
            name
          }
        }
      }
      user {
        _id
        firstName
        lastName
        role
      }
      status
      createdAt
      updatedAt
    }
  }
`;

export const GET_MY_QUOTES = gql`
  query getMyQuotes {
    getMyQuotes {
      id
      services {
        service {
          name
          description
        }
        price
        nextServiceDate
      }
      status
      createdAt
    }
  }
`;

export const GET_MY_ACCOUNTS = gql`
  query getMyAccounts {
    getMyAccounts {
      id
      accountNumber
      balance
      invoices {
        id
        billingPeriodStartDate
        billingPeriodEndDate
        totalDue
        status
        charges {
          description
          amount
          date
        }
        payments {
          amount
          date
          stripePaymentId
        }
      }
      services {
        service {
          name
        }
        price
      }
      createdAt
    }
  }
`;

export const CREATE_STRIPE_CHECKOUT_SESSION = gql`
  query createStripeCheckoutSession($accountId: ID!, $invoiceId: ID!) {
    checkout(accountId: $accountId, invoiceId: $invoiceId) {
      session
    }
  }
`;