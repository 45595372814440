import React from 'react';
import { Navigate } from 'react-router-dom';
import { useGetUser } from '../../utils/auth';

const AdminProtectedRoute = ({ children }) => {
  const { user, loading, error } = useGetUser();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error || !user || user.role !== 'ADMIN') {
    return <Navigate to="/login" />;
  }

  return children;
};

export default AdminProtectedRoute;