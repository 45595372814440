// ProtectedRoute.jsx
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useGetUser } from '../../utils/auth';

const ProtectedRoute = ({ children }) => {
  const { user, loading, error } = useGetUser();

  if (loading) {
    // You can replace this with a loading spinner or similar if you like
    return <div>Loading...</div>;
  }

  if (error || !user || (user.role !== 'EMPLOYEE' && user.role !== 'ADMIN')) {
    // Redirect them to the login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" />;
  }

  return children;
};

export default ProtectedRoute;
