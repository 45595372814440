import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Typography, Container, Link } from '@mui/material';

const VerifyEmailPrompt = () => {
  return (
    <Container maxWidth="sm">
      <Typography variant="h5">Verify Your Email</Typography>
      <Typography>
        Thank you for signing up! We've sent you an email with a verification link. Please check your inbox and click on the link to verify your email address.
      </Typography>
      <Typography>
        If you've already verified your email, you can <Link component={RouterLink} to="/login">log in</Link>.
      </Typography>
    </Container>
  );
};

export default VerifyEmailPrompt;